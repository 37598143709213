@import "./mixin.scss";
* {
  .experiencesSection {
    padding-top: 30px;
    // border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding-top: 50px;
    .experiences {
      width: 100%;
      margin: auto;
      height: auto;
      padding: 0 20px;
      box-sizing: border-box;
      .experienceItem {
        position: relative;
        padding: 20px 20px 20px 20px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 150px calc(100% - 180px);
        grid-gap: 30px;
        .companyImage {
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            width: 150px;
            border: 3px solid #ccc;
            border-radius: 10px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &:first-child {
          padding-top: 0px;
        }
        &:last-child {
          padding-bottom: 0px;
        }
        .companyDetails {
          display: flex;
          flex-flow: column;
          justify-content: center;
          .role,
          .year {
            height: 25px;
            display: flex;
            font-size: 0.9em;
            align-items: flex-start;
          }
          .company {
            color: #b38a4c;
            font-size: 1.3em;
            font-weight: 900;
            height: 40px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
