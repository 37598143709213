@import "./mixin.scss";
* {
  .skillSection {
    // padding: 40px;
    // box-sizing: border-box;
    // min-height: 600px;
    // display: flex;
    // align-items: flex-end;
    // justify-content: center;
    // flex-flow: column;
    // position: relative;
    padding-top: 50px;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    .skillsBackground {
      // background-image: url("../image/skills_2.jpg");
      // @include backgroundImageCover;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      opacity: 0.7;
      top: 0px;
      left: 0px;
    }
    .skillsContainer {
      // max-width: 800px;
      // margin-right: 10%;
      // padding-top: 8%;
      display: flex;
      flex-flow: column;
      align-content: center;
      // visibility: hidden;
      &.visibleSection {
        // visibility: visible;
      }
      > h2 {
        color: #513c58 !important;
      }
      .skills {
        // max-width: 800px;
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-gap: 5px;
        .skill {
          height: 35px;
          display: grid;
          grid-template-columns: 120px Auto;
          align-items: center;
          // justify-content: space-between;
          justify-content: space-around;
          font-size: 1em;
          padding: 0 20px;
          box-sizing: border-box;
          border-radius: 5px;
          // animation: skillsInFlow 0.5s linear;
          color: #333; // Decent
          font-weight: 500;
          &:hover {
            // background-color: #ebebeb;
            .ratings {
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}

@keyframes skillsInFlow {
  0% {
    transform: translateX(-200%);
  }
  25% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-0%);
  }
}
