$font-size: 14px;

* {
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
    .dropdown,
    .treeDropdown {
        width: 100%;
        height: 100%;
        .dropDown-placeholder,
        .treeDropdown-placeholder {
            width: 100%;
            height: 100%;
        }
    }
    .dropdown-container,
    .treeDropdown-container {
        z-index: 100;
        top: 0px;
        left: 0px;
        width: 0;
        height: 0;
        transition: height 0.15s ease-out;
        .dropdown-container-relative {
            .modalArrow {
                position: absolute;
                z-index: 1;
                &:before {
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #ededed;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                &:after {
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-bottom: 9px solid #ededed;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                }
            }
            position: absolute;
            display: block;
            overflow: hidden;
            transition: max-height 0.3s ease-out;
            font-size: $font-size;
            padding-top: 9px;
            .dropdown-container-content {
                box-shadow: 2px 2px 5px #ebebeb;
                background-color: #ededed;
                border: 1px solid #ebebeb;
                .dropDownHeader {
                    height: 35px;
                    font-size: $font-size;
                    color: #333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .buttonsField {
                    height: 40px;
                    font-size: $font-size;
                    border-top: 1px solid #b8b8b8;
                    color: #4286f4;
                    cursor: pointer;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    .commonButton {
                        width: 60px;
                        box-sizing: border-box;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        font-size: $font-size;
                    }
                    .applyFilter {
                        order: 2;
                        margin-left: auto;
                        margin-right: 0px;
                    }
                    .primaryButton {
                        background: linear-gradient(#1c8fff, #006bdd);
                        border: 1px solid #0058d8;
                        padding: 0px 12px;
                        height: 22px;
                        color: #fff;
                        border-radius: 3px;
                        line-height: 21px;
                        cursor: pointer;
                        &:hover {
                            border: 1px solid #002d80;
                            background: #004591;
                            background: linear-gradient(#006bdd, #004591);
                        }
                        &:active {
                            border: 1px solid #0058d8;
                            background: linear-gradient(#006bdd, #004591);
                        }
                    }
                    .primaryButtonDisabled {
                        pointer-events: none;
                        border: 1px solid #006bdd;
                        background: linear-gradient(#b1d7fd, #6ab3fa);
                    }

                    .secondaryButton {
                        background: linear-gradient(#ffffff, #eaeaea);
                        border: 1px solid #eaeaea;
                        padding: 0px 12px;
                        height: 22px;
                        color: #000000;
                        border-radius: 3px;
                        line-height: 22px;
                        cursor: pointer;
                        &:hover {
                            background: #004591;
                            background: linear-gradient(#fafafa, #eaeaea);
                        }
                        &:active {
                            background: linear-gradient(#767676, #eaeaea);
                        }
                    }
                    .secondaryButtonDisabled {
                        pointer-events: none;
                        background: linear-gradient(#b1d7fd, #6ab3fa);
                    }
                    .iconColor {
                        color: #666666;
                    }
                }
                .searchSection {
                    border-bottom: 1px solid #b8b8b8;
                    padding-top: 5px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    .search-box {
                        height: 30px;
                    }
                    .searchBoxContainer {
                        padding: 0 10px;
                    }
                }
                .listNavigation {
                    overflow-x: auto;
                    box-sizing: border-box;
                    margin: 0px;
                    padding: 10px;
                    color: #2a2a2a;
                    .dropdown-list {
                        padding: 0px;
                        margin: 0px;
                        position: relative;
                        .icon-UnCheckedMark {
                            &:before {
                                content: "\e805";
                                color: #999;
                                font-weight: 600;
                                margin-right: 8px;
                            }
                        }
                        .icon-Single-CheckMark {
                            &:before {
                                content: "\e806";
                                color: #3a9afc;
                                font-weight: 600;
                                margin-right: 8px;
                            }
                        }
                        .icon-CheckMark {
                            &:before {
                                content: "\e807";
                                color: #3a9afc;
                                font-weight: 600;
                                margin-right: 8px;
                            }
                        }
                        .parentItem {
                            padding: 0 5px;
                        }
                        .childItem {
                            padding-left: 20px;
                        }
                        .dropdown-list-item {
                            position: absolute;
                            .itemNameLabel {
                                box-sizing: border-box;
                                font-size: 12px;
                                font-weight: 400;
                                width: 100%;
                                height: 100%;
                                line-height: 100%;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                .itemNameLabelText {
                                    width: calc(100% - 20px);
                                    display: inline-block;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    height: auto;
                                    line-height: 29px;
                                }
                            }
                        }
                    }
                }
                .dropdown-list {
                    overflow-x: auto;
                    padding: 10px;
                    box-sizing: border-box;
                    margin: 0px;
                    color: #2a2a2a;
                    .dropdown-list-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        box-sizing: border-box;
                        white-space: nowrap;
                        cursor: pointer;
                        &:focus {
                            background: #ccc;
                            opacity: 0.8;
                            outline: none;
                        }
                        .valueParagraph {
                            width: 100%;
                            padding: 0 5px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            margin: 0px;
                            cursor: pointer;
                            &:before {
                                content: " ";
                                width: 5px;
                                height: 9px;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                                margin-bottom: 2px;
                                margin-right: 10px;
                                box-sizing: border-box;
                            }
                            &:hover,
                            &:focus {
                                background: #ebebeb;
                                opacity: 0.8;
                            }
                            .itemtext {
                                font-size: $font-size;
                                font-weight: 400;
                                width: 100%;
                                height: 100%;
                                line-height: 100%;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                            }
                            .checkmark {
                                height: 15px;
                                width: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #fff;
                            }
                        }
                    }
                    .selected {
                        .valueParagraph {
                            &:before {
                                border: solid #3a9afc;
                                border-width: 0 2px 2px 0;
                            }
                        }
                    }
                    .disableClass {
                        color: #ccc;
                        pointer-events: none;
                    }
                    .dropdown-list-header {
                        display: flex;
                        align-items: center;
                        color: #4286f4;
                        padding-left: 5px;
                    }
                    .dropdown-list-divider {
                        height: 0px;
                        border-top: 1px solid #ccc;
                    }
                }
            }
        }
    }
    @keyframes dropdownIn {
        0% {
            max-height: 0px;
        }
        100% {
            max-height: 100%;
        }
    }
    @keyframes dropdownOut {
        0% {
            max-height: 100%;
        }
        100% {
            display: none;
            max-height: 0px;
        }
    }
}
