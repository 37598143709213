@import "./information.scss";
@import "./tabs.scss";
@import "./overview.scss";
@import "./work.scss";
@import "./skills.scss";
@import "./education.scss";
@import "./experiences.scss";

* {
  @mixin backgroundImageCover {
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  #root {
    .resumeLandingPage {
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
      .headerSection {
        width: 100%;
        height: 190px;
        overflow: hidden;
        background: #fff;
      }
      .sectionHeader {
        font-weight: 400;
        text-align: center;
        margin: 0px;
        margin-bottom: 15px;
        color: #22a39f;
        color: #513c58;
      }
      .fieldsetHeader {
        color: #b38a4c;
        font-size: 16px;
        padding: 0 10px;
        background-color: #fff;
        border-radius: 15px;
        box-sizing: border-box;
        height: 40px;
        width: 120px;
        border: 0px;
        box-shadow: 0px 2px 5px 3px #b5b5b5;
        font-weight: 600;
        > span {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .contentSection {
        width: 100%;
        height: calc(100% - 190px);
        overflow: auto;
        scroll-behavior: smooth;
        position: relative;
      }
      .feildArticle {
        font-size: 1em;
        letter-spacing: 1px;
        background: #fff;
        max-width: 1024px;
        min-width: 0;
        width: 90%;
        margin: auto;
        line-height: 30px;
        padding: 20px 40px 40px 40px;
        box-sizing: border-box;
        border-color: #e7f3ff;
        border: 0px;
        border-radius: 20px;
        box-shadow: 0px 2px 5px 3px #b5b5b5;
        opacity: 0;
      }
    }
    .separator {
      height: 3px;
      width: 100%;
      background-image: linear-gradient(45deg, transparent, #999, transparent);
    }
  }
}

@import "./media.scss";
@import "./animation.scss";
@import "./fonts.scss";
