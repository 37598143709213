* {
  .ratings {
    display: grid;
    grid-gap: 5px;
    grid-auto-flow: column;
    width: auto;
    height: auto;
    grid-auto-columns: min-content;
    .rateCanvas {
      //   display: grid;
      //   grid-gap: 5px;
      //   grid-auto-flow: column;
    }
  }
}
