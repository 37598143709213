html {
  overflow: hidden;
  height: 100%;
}
* {
  body {
    overflow: hidden;
    height: 100%;
    margin: 0;
    font-size: 1em;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    background-color: #ededed;
    color: #1d1d1f;
    font-style: normal;
  }
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .portfolioIframe {
      width: 100%;
      height: 100%;
      border: 0px;
    }
  }
}
