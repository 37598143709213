* {
  .tabsContainer {
    width: 100%;
    // background-color: #e7f0f0;
    border-bottom: 1px solid #ccc;
    height: 40px;
    box-sizing: border-box;
    .tabDropdown {
      width: 140px;
      height: 30px;
      margin: auto;
      background: rgb(255, 255, 255);
      // transition: all 0.5s ease 0s;
      border: 1px solid #0073e6;
      color: #0073e6;
      border-radius: 5px;
      font-size: 0.9em;
      box-sizing: border-box;
      .dropDown-placeholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0 10px;
        box-sizing: border-box;
        .tabSelected {
          display: flex;
          align-items: center;
          // justify-content: center;
          height: 100%;
          width: calc(100% - 20px);
          padding-left: 10px;
        }
        .arrowDown {
          height: 6px;
          width: 6px;
          border: 2px solid #0073e6;
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotate(45deg);
          margin-bottom: 6px;
          margin-right: 10px;
        }
      }
      .dropdown-container {
        .dropdown-container-relative {
          padding: 1px 0 0 0;
          .dropdown-container-content {
            background: #fff;
          }
          .modalArrow {
            &::after {
              border-color: #fff;
            }
          }
        }
      }
    }
    .tabs {
      font-size: 0.9em;
      height: 35px;
      display: flex;
      // align-items: center;
      justify-content: center;
      position: relative;
      width: 400px;
      margin: auto;
      .tab {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        z-index: 1;
        cursor: pointer;
      }
      .selected {
        color: #b38a4c;
        border-radius: 5px;
      }
      .tabSlider {
        background: rgb(255, 255, 255);
        width: 100px;
        height: 32px;
        position: absolute;
        top: 0px;
        transition: all 0.5s ease 0s;
        border-radius: 5px;
        border: 1px solid #b38a4c;
        box-sizing: border-box;
      }
    }
  }
}
