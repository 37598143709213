*{
    .tooltip{
        position: relative;
        .tooltip-placeholder{
            width: 100%;
            height: 100%;
        }
        .tooltip-content{
            // background:black;
            position: absolute;
            right: 0px;
        }
    }
}