@mixin backgroundImageCover {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: 50% 50%;
}

@mixin backgroundImage {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
