@import "./mixin.scss";

* {
  .workContainer,
  .portfolioContainer {
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    .feildArticle {
      position: relative;
    }
    .workCards {
      display: flex;
      width: 100%;
      overflow: hidden;
      scroll-snap-type: x mandatory;
      transition: scroll 2s;
      scroll-behavior: smooth;
      // overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        // width: 0px;
        // height: 0px;
        display: none;
      }
      .projectDetails {
        float: right;
        flex-flow: column;
        flex-shrink: 0;
        width: 100%;
        font-size: 1em;
        padding: 20px 30px;
        box-sizing: border-box;
        scroll-snap-align: start;
        .row {
          min-height: 30px;
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .labelClass {
            font-weight: 900;
            color: #7b7992;
            width: 100px;
            flex-shrink: 0;
          }
          .colonClass {
            width: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
          }
          .valueClass {
            color: #1d1d1f;
            overflow: hidden;
            word-break: break-word;
          }
        }
        .projectDuration {
          color: #7b7992;
          font-size: 0.9em;
          font-weight: 500;
          justify-content: space-between;
          .portoFlioLink {
            color: #1968ae;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 600;
          }
        }
        .projectName {
          color: #b38a4c;
          font-size: 1.3em;
          font-weight: 700;
          margin: 0px 0 15px 0;
          margin-top: 0px;
        }
        .projectDescription {
          margin-top: 15px;
          .descriptionList {
            list-style: num;
          }
        }
      }
    }
    .projectsNavigator {
      // width: 70%;
      // display: grid;
      // grid-template-columns: 25px auto 25px;
      // max-width: 25px calc(100% - 50px) 25px;
      // margin: 20px auto 0px auto;
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 30px;
      padding: 0 20px;
      box-sizing: border-box;
      .arrow {
        width: 30px;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: " ";
          border: 3px solid #999;
          border-left: transparent;
          border-bottom: transparent;
          width: 15px;
          height: 15px;
          z-index: 1000;
          cursor: pointer;
          transition: all 0.5s;
          opacity: 1;
          display: block;
          box-sizing: border-box;
        }
        &.leftArrow {
          &:before {
            transform: rotate(225deg);
            top: calc(50% - 10px);
            left: 10px;
          }
        }
        &.rightArrow {
          &:before {
            transform: rotate(45deg);
            top: calc(50% - 10px);
            right: 10px;
          }
        }
        &.hideArrow {
          &:before {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
      .projectSelector {
        flex-shrink: 0;
        max-width: calc(100% - 70px);
        .projectSelectorUl {
          list-style: none;
          display: grid;
          grid-auto-flow: column;
          grid-gap: 15px;
          // justify-content: center;
          margin: 0;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 0.9em;
          overflow: hidden;
          position: relative;
          .projectNoLi {
            text-align: center;
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            background: #fff;
            transition: width 0.6s;
            box-sizing: border-box;
            line-height: 28px;
          }
          .selectedProjectNO {
            border: 2px solid #b38a4c;
            width: 60px;
            border-radius: 30px;
            color: #b38a4c;
            background: #fff;
          }
        }
      }
    }
    .workInfo {
      z-index: 10001;
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      right: 20px;
      .workInfoIcon {
        cursor: pointer;
        z-index: 10001;
        width: 100%;
        height: 100%;
        display: inline-block;
        // @include backgroundImage;
        background-image: url("../image/info_3.svg");
        font-size: 1.625em;
        font-weight: 600;
        // &:hover{
        //   &::before{
        //     content: '\e902';
        //   }
        // }
        // &:hover{
        //   background: black;
        //   color: #fff;
        // }
      }
    }
  }
  .contentTooltip {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.825em;
    background: rgb(14, 30, 37);
    border: 1px solid rgb(14, 30, 37);
    border-radius: 5px;
    padding: 5px 10px;
  }
}
