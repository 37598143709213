@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?hkznoj');
  src:  url('../fonts/icomoon.eot?hkznoj#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?hkznoj') format('truetype'),
    url('../fonts/icomoon.woff?hkznoj') format('woff'),
    url('../fonts/icomoon.svg?hkznoj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone{
  &::before{
    content: '\e922';
  }
}

// .icon-information{
//   &::before{
//     content: '\e901';
//   }
// }

// .icon-information-filled{
//   &::before{
//     content: '\e902';
//   }
// }