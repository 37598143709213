@import "./mixin.scss";
* {
  .personalInformation {
    width: 100%;
    // background-color: #e7f0f0;
    box-sizing: border-box;
    height: 150px;
    .container {
      max-width: 1024px;
      width: 90%;
      margin: auto;
      display: flex;
      box-sizing: border-box;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      .personalDetails {
        display: flex;
        box-sizing: border-box;
        height: 100%;
        align-items: center;
        .personImage {
          @include backgroundImage;
          background-image: url("../image/profilePic.jpg");
          width: 120px;
          height: 130px;
          border-radius: 15px;
        }
        .nameAndProfile {
          margin-left: 15px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          .personName {
            font-size: 1.4em;
            color: #b38a4c;
          }
          .profile {
            margin-top: 5px;
            margin-bottom: 10px;
            font-size: 0.9em;
            color: #333;
          }
        }
      }
      .emailAndSocial {
        font-size: 0.9em;
        .linkedin {
          > a {
            .label {
              &:hover {
                transform: scalex(1.03);
              }
            }
          }
        }
        .emilIds {
          li {
            > a {
              .label {
                &:hover {
                  transform: scalex(1.03);
                }
              }
            }
          }
        }
        .emilIds,
        .phoneNumbers,
        .linkedin {
          color: #333;
          list-style: none;
          padding: 0;
          margin: 0 0 15px 0;
          display: flex;
          align-items: center;
          color: #1968ae;
          cursor: pointer;
          > li {
            display: flex;
            align-items: center;
          }
          .gmailIcon,
          .phoneIcon,
          .linkedinIcon {
            @include backgroundImage;
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            cursor: default;
          }
          .phoneIcon {
            // background-image: url("../image/iconfinder_Phone_sqaure.svg");
            font-size: 1.375em;
          }
          .gmailIcon {
            background-image: url("../image/iconfinder_gmail_1220340.svg");
          }
          .linkedinIcon {
            background-image: url("../image/iconfinder_social_media_applications_14-linkedin_4102586.svg");
          }
        }
        .linkedinLink,
        .emailLink {
          color: #1968ae;
          text-decoration: none;
          display: flex;
        }
      }
    }
  }
}
