* {
  @media (max-width: 500px) {
    .personalInformation {
      .container {
        .emailAndSocial {
          .emilIds,
          .linkedin {
            .linkedinLink,
            .emailLink {
              .label {
                display: none;
              }
              .linkedinIcon,
              .gmailIcon {
                margin: 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .educationSection {
      .education {
        .eduactionItem {
          .streamName,
          .academyName,
          .year {
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .personalInformation {
      height: 170px;
      .container {
        .personalDetails {
          .nameAndProfile {
            margin-left: 15px;
            width: calc(100% - 130px);
            .profile {
              margin: 0px;
            }
          }
        }
        .emailAndSocial {
          .phoneNumbers {
            height: 100%;
            margin: 10px 10px 5px 0;
          }
          .emilIds,
          .linkedin {
            height: 100%;
            margin: 5px 10px 5px 0;
            display: inline-flex;
          }
        }
      }
    }
  }

  @media (max-width: 650px) {
    #root {
      .resumeLandingPage {
        .feildArticle {
          padding: 20px 30px 40px 30px;
        }
        .workContainer,
        .portfolioContainer {
          .workCards {
            .projectDetails {
              padding: 20px 10px;
            }
          }
          .workInfo {
            bottom: 5px;
          }
        }
        .experiencesSection {
          .experiences {
            .experienceItem {
              grid-template-columns: auto;
              grid-gap: 30px;
              justify-content: center;
            }
          }
        }
        .skillSection {
          .skillsContainer {
            .skills {
              grid-template-columns: auto;
              grid-gap: 10px;
            }
          }
        }
      }
    }
  }
}
