* {
  .overviewContainer {
    padding-top: 30px;
    box-sizing: border-box;
  }
}

@keyframes darkwaves {
  0% {
    // transform: translateX(200%);
    opacity: 0;
  }
  50% {
    // transform: translateX(100%);
    opacity: 0.5;
  }
  100% {
    // transform: translateX(0px);
    opacity: 1;
  }
}
