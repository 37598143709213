@import "./mixin.scss";
* {
  .educationSection {
    padding: 50px 0;
    min-height: 600px;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    position: relative;
    .education {
      width: 100%;
      margin: auto;
      height: auto;
      padding: 0 20px;
      box-sizing: border-box;

      .eduactionItem {
        position: relative;
        padding: 20px 20px 20px 20px;
        box-sizing: border-box;

        &:first-child {
          padding-top: 0px;
        }
        &:last-child {
          padding-bottom: 0px;
        }
        .eduactionItemBackground {
          width: 100%;
          height: 100%;
          background: #000;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          border-radius: 10px;
          opacity: 0.4;
        }

        .streamName,
        .academyName,
        .year {
          min-height: 30px;
          display: flex;
          font-size: 0.9em;
          align-items: center;
          line-height: 20px;
        }
        .degreeName {
          color: #b38a4c;
          font-size: 1.3em;
          font-weight: 900;
          min-height: 40px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
